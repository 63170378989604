@import '~react-image-gallery/styles/css/image-gallery.css';

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: unset;
}

.image-gallery-slide .image-gallery-image {
  height: 100vh;
  object-fit: cover;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

input:-webkit-autofill {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 100vh;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 700;
  opacity: 0.8;
}

h2,
h3 {
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-size: 1.3rem;
}

h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.amp,
.serif {
  font-family: 'Libre Baskerville', serif;
  font-style: italic;
  font-weight: normal;
  text-transform: lowercase;
}

.photo {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.content {
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.content::-webkit-scrollbar {
  display: none;
}

.logo {
  width: 25vw;
  margin: 0 0 5vh 0;
  opacity: 0.5;
}

.section {
  min-height: 90vh;
  padding: 5vh;
}

.section.prelude p {
  margin: 0.5rem 0;
}

.intro {
  background-color: #8389fd;
}

.intro-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.intro-buttons .MuiButton-root {
  margin: 5px;
}

.location {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 18px;
}

.date {
  font-size: 20px;
  margin-bottom: 5vh;
}

.nav {
  list-style: none;
  display: flex;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 10px;
  justify-content: space-between;
  height: 5vh;
}

a:hover {
  opacity: 1;
}

.prelude {
  background-color: #a38af0;
}

.schedule {
  background-color: #b789e7;
}

.travel {
  background-color: #ca88de;
}

.things {
  background-color: #dd85d5;
}

.rsvp {
  background-color: #f980c6;
}

.MuiPaper-root.thanks-form {
  padding: 5vh;
}

.thanks-form p {
  font-family: 'Libre Baskerville', serif;
  font-style: italic;
  color: #ffffff;
  margin-bottom: 3vh;
}

.item {
  margin: 36px 0;
  text-align: left;
  display: flex;
}

.item h3 {
  margin-top: 0;
  line-height: 1.5rem;
  opacity: 1;
}

.item p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.3rem;
}

.item p a {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.6);
}

.item p a:hover {
  border-bottom: none;
}

.item .MuiSvgIcon-root {
  margin-right: 12px;
}

.item .description {
  text-transform: lowercase;
  padding-left: 12px;
}

.item p.subtitle {
  margin: 10px 0 5px 0;
  font-weight: 600;
}

.MuiFormLabel-root.rsvp-label,
.MuiFormLabel-root.Mui-focused.rsvp-label {
  font-family: 'Libre Baskerville', serif;
  font-style: italic;
  color: #ffffff;
  margin-bottom: 3px;
}

.rsvp-forms {
  display: flex;
  column-gap: 20px;
}

.rsvp-form {
  flex: 1;
}

.content .rsvp-form .MuiButton-root {
  margin: 20px 5px 10px 0;
}

.content .thanks-form .MuiButton-root {
  margin: 0 10px 10px 10px;
}

.registry {
  margin-top: 5vh;
}

@media only screen and (max-width: 1199px) {
  .App {
    flex-direction: column;
  }

  .nav {
    display: none;
  }

  .photo {
    min-height: 60vh;
    height: 60vh;
  }

  .content {
    overflow: initial;
  }

  .intro,
  .intro-content {
    min-height: 30vh;
    height: 30vh;
  }

  .logo {
    width: 33vw;
    margin: 2vh 0;
  }

  .date {
    margin-bottom: 3vh;
  }
}

@media only screen and (max-width: 767px) {
  .photo {
    min-height: 60vh;
    height: 60vh;
  }

  .section {
    min-height: 94vh;
    padding: 3vh;
  }

  .intro,
  .intro-content {
    min-height: 42vh;
    height: 42vh;
    padding-bottom: 12vh;
    justify-content: flex-start;
  }

  .photo img {
    position: relative;
    top: -10vh;
  }

  .logo {
    width: 60vw;
  }

  .location {
    font-size: 18px;
    line-height: 24px;
  }

  .date {
    font-size: 16px;
  }

  .rsvp .MuiFormHelperText-root {
    font-size: 0.8rem;
  }

  .rsvp-forms {
    display: block;
    column-gap: unset;
  }

  .content .rsvp-form .MuiButton-root {
    margin: 0;
  }
}
